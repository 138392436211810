import React, { useEffect, useRef } from "react";
import { XCircle } from "phosphor-react";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import styles from "./ModalWrapper.module.scss";

const ModalWrapper = ({ children, showModal, setShowModal }) => {
  const selectRef = useRef(null);


  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__box}>
        <div ref={selectRef} className={styles.wrapper__main}>
          <div
            // onClick={() => setShowModal(false)}
            className={styles.wrapper__cancel}
          >
            {/* <XCircle size={40} color="#8E8E8E" weight="fill" /> */}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
