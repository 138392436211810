import React from "react";
import styles from "./Navbar.module.scss";

//images
import Logo from "../../assets/images/logo.svg";

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <img className={styles.navbar__logo} src={Logo} alt="knit logo" />
      {/* <h2 className={styles.navbar__text}>Kiftcode</h2> */}
    </div>
  );
};

export default Navbar;
