import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGiftCode, validateLink } from "../../services";
import styles from "./Home.module.scss";

//components;
import Navbar from "../../components/Navbar/Navbar";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import PaymentOption from "../../components/PaymentOption/PaymentOption";
import Invalid from "../../components/Invalid/Invalid";
import PaymentCrypto from "../../components/PaymentCrypto/PaymentCrypto";

const Home = ({ formData, setFormData }) => {
  const [currentStep, setCurrentStep] = useState("crypto-payment");
  const [showModal, setShowModal] = useState(false);
  const { reference } = useParams();

  const { data, isLoading } = useQuery(
    ["validate link"],
    () => validateLink(reference ? reference : ""),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        console.log(data, "link validation");
        setShowModal(true);
      },
      onError: (error) => {
        console.log({ error });
        setCurrentStep("invalid");
        setShowModal(true)
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  return (
    <>
      <div className={styles.home}>
        <Navbar />

        <div className={styles.home__main}>
          {isLoading && <h3>Please wait...</h3>}
        </div>
      </div>

      {showModal && (
        <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
          {currentStep === "crypto-payment" && (
            <PaymentCrypto
              _coinOptions={data?.networks}
              transactionData={data?.link}
              userData={data?.user}
            />
          )}
          {currentStep === "invalid" && (
            <Invalid
            />
          )}
        </ModalWrapper>
      )}
    </>
  );
};

export default Home;
