import React, { useState, useRef, useEffect } from "react";
import { CaretDown } from "phosphor-react";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import styles from "./Select.module.scss";

const Select = ({ heading, active, setActive, options }) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(selectRef);
  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowOptions(false);
    }
  }, [isOutOfFocus]);

  return (
    <div className={styles.select}>
      <div className={styles.select__heading}>
        <h6>{heading}</h6>
        <div className={styles.select__content}>
          <img src={active?.icon} alt="active image" />
          <h5>{active?.name}</h5>
        </div>
      </div>

      <div
        onClick={() => setShowOptions(!showOptions)}
        className={styles.select__dropdown}
      >
        <h5>{active?.name}</h5>
        <div>
          <CaretDown size={12} weight="fill" color="#1C1B1F" />
        </div>

        {showOptions && !isOutOfFocus ? (
          <div ref={selectRef} className={styles.select__dropdown__options}>
            {options?.map((option, index) => (
              <div
                onClick={() => {
                  setActive(option);
                  setShowOptions(false);
                }}
                key={index}
                className={styles.select__dropdown__option}
              >
                <img src={option?.icon} />
                <p>{option?.name}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Select;
