import React, { useEffect } from "react";
import styles from "./Success.module.scss";

//images
import CheckCircle from "../../assets/icons/check_circle (1).png";

const Success = ({ redirectUrl }) => {
  useEffect(() => {
    setTimeout(() => {
      redirectUrl
        ? (window.location.href = redirectUrl)
        : (window.location.href = "https://www.useknit.io");
    }, 10000);
  }, [redirectUrl]);

  return (
    <div className={styles.success}>
      <img src={CheckCircle} className={styles.success__icon} />
      <p className={styles.success__message}>Payment Successful</p>
      {/* <p className={styles.success__message}>
        Giftcode applied:{" "}
        <b onClick={() => handleCopy(giftcode)}>
          {giftcode} <Copy size={22} color="#000" />
        </b>
      </p> */}
      <button
        onClick={() =>
          redirectUrl
            ? (window.location.href = redirectUrl)
            : (window.location.href = "https://www.useknit.io")
        }
      >
        Go home
      </button>
    </div>
  );
};

export default Success;
