import React, { useEffect } from "react";
import styles from "./Failed.module.scss";

//images
import FailedIcon from "../../assets/icons/failed.png";

const Failed = ({ redirectUrl }) => {
  useEffect(() => {
    setTimeout(() => {
      redirectUrl
        ? (window.location.href = redirectUrl)
        : (window.location.href = "https://www.useknit.io");
    }, 5000);
  }, [redirectUrl]);
  return (
    <div className={styles.failed}>
      <img src={FailedIcon} className={styles.failed__icon} />
      <p className={styles.failed__message}>Payment Failed</p>

      <div className={styles.failed__buttons}>
        <button
          onClick={() => (window.location.href = "https://www.useknit.io")}
        >
          Try again
        </button>
        <button
          onClick={() =>
            redirectUrl
              ? (window.location.href = redirectUrl)
              : (window.location.href = "https://www.useknit.io")
          }
        >
          Go home
        </button>
      </div>
    </div>
  );
};

export default Failed;
