import { Http } from "../utils/http";

export const getGiftCode = (payload) =>
  Http.post("/giftcodes", payload);


export const createTransactionFromLink = (payload) =>
  Http.post("/transactions/create-from-link", payload);


  
export const getPurchasedGiftCode = (reference , email) => Http.get(`/giftcodes/status/${reference}/${email}`);


export const validateLink = (reference) => Http.get(`/transactions/link/${reference}`);

export const getTransaction = (reference, id) => Http.get(`/transactions/link/${reference}/${id}`);

export const getCoinValueForLocalCurrency = (
  local_currency,
  local_amount,
  coin,
  user_id
) =>
  Http.get(`/transactions/${local_currency}/${local_amount}/convert/${coin}/${user_id}`);

