import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

// pages
import LandingPage from "../pages/Home/Home";
import Redirect from "../pages/Redirect/Redirect";
import Layout from "../layout/Layout";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route
          path="/:reference"
          element={
            <Layout>
              <LandingPage />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout link="https://www.useknit.io">
              <LandingPage />
            </Layout>
          }
        />

        {/* <Route path="*" element={<Navigate to="/"/> }/> */}
      </Switch>
    </>
  );
};

export default Routes;
