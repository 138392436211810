import { cloneElement, useState, useEffect } from "react";

const Layout = ({ children, link }) => {
  const [formData, setFormData] = useState({
    card_holder_name: "",
    cvc: "",
    expiry: "",
    card_number: "",
    card_holder_email: "",
  });

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  return (
    <div>
      {" "}
      {cloneElement(children, {
        formData,
        setFormData,
      })}
    </div>
  );
};

export default Layout;
