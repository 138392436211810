import React from "react";
import { Copy } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import styles from "./Invalid.module.scss";

//images
import InvalidIcon from "../../assets/icons/failed.png";

const Invalid = ({ setTransaction }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.invalid}>
      <img src={InvalidIcon} className={styles.invalid__icon} />
      <p className={styles.invalid__message}>Payment link invalid</p>

      <div className={styles.invalid__buttons}>
      
        <button
          onClick={() => (window.location.href = "https://www.useknit.io")}
        >
          Go home
        </button>
      </div>
    </div>
  );
};

export default Invalid;
